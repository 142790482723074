<template>
    <div class="business-wrapper" @click="gotoBottom">
        <div class="business" :class="{ 'box-shadow': isCoupon, 'flex-start': microCouponData.hasCoupon || isCoupon }">
            <div class="visitor">
                <div v-if="showMyData">
                    <div class="first-title">锁定微信(个)</div>
                    <div class="bold-data" v-if="!isActivityDetail">
                        {{ businessData.total_number }}
                    </div>
                    <div class="bold-data" v-if="isActivityDetail">
                        {{ businessData.my_total_number }}
                    </div>
                    <!-- <div class="today-new">
                        今日新增
                        <div
                            v-show="!isActivityDetail"
                            class="visitor-number"
                            :style="businessData.today_number == 0 ? 'color:#666666' : ''"
                        >
                            {{ businessData.today_number | filterNumber }}
                        </div>
                        <div
                            v-if="isActivityDetail"
                            class="visitor-number"
                            :style="businessData.my_today_number == 0 ? 'color:#666666' : ''"
                        >
                            {{ businessData.my_today_number | filterNumber }}
                        </div>
                    </div> -->
                </div>
                <div v-if="false" class="total-visitor" >
                    <div class="first-title">{{ showMyData ? '活动' : '我的' }}锁客总数</div>
                    <div class="bold-data">{{ businessData.total_number || '0' }}</div>
                    <!-- <div class="today-new">
                        今日新增
                        <div class="visitor-number" :style="businessData.today_number == 0 ? 'color:#666666' : ''">
                            {{ businessData.today_number | filterNumber }}
                        </div>
                    </div> -->
                </div>
            </div>
            <div class="line" />
            <div class="mine" @click="goMerchantList">
                <div class="first-title">获取手机号(个)</div>
                <div class="bold-data">{{ businessData.count_all || '0' }}</div>
            </div>
            <!-- <div class="today-new">
                    今日新增
                    <div class="business-number" :style="businessData.today_number == 0 ? 'color:#666666' : ''">
                        {{ businessData.count_today | filterNumber }}
                    </div>
                </div> -->
            <!-- </div> -->
            <!-- <div class="line" :class="[isCoupon ? 'xs-line' : '']" v-if="isInList || isCoupon"></div> -->
            <!-- <div class="mine">
                <div class="first-title">锁客用户数</div>
                <div class="bold-data">{{ couponOverview.total_validated_num || '0' }}</div>
                <div class="today-new">
                    今日新增
                    <div class="business-number" :style="couponOverview.today_visitor_num == 0 ? 'color:#666666' : ''">
                        {{ couponOverview.today_validated_num | filterNumber }}
                    </div>
                </div>
            </div> -->

            <!-- 优惠券活动数据 -->
            <div v-if="isCoupon" class="activity-coupon">
                <div class="line xs-line" />
                <div class="coupon-recive">
                    <div class="first-title">领券用户数</div>
                    <div class="bold-data">
                        {{ couponOverview.total_received_num }}
                    </div>
                </div>
                <div class="line xs-line" />
                <div class="coupon-use">
                    <div class="first-title">用券用户数</div>
                    <div class="bold-data">
                        {{ couponOverview.total_used_num }}
                    </div>
                </div>
            </div>
            <!-- 微页面优惠券数据 -->
            <div v-if="microCouponData.hasCoupon" class="micro-coupon">
                <div class="line xs-line" />
                <div class="coupon-recive">
                    <div class="first-title">领券用户数</div>
                    <div class="bold-data">{{ microCouponData.receiveTotal || '0' }}</div>
                </div>
                <div class="line xs-line" />
                <div class="coupon-use">
                    <div class="first-title">用券用户数</div>
                    <div class="bold-data">{{ microCouponData.useTotal || '0' }}</div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { getStaffId } from '../../../api/index';
import { getCookie } from '@/utils/cookies.js';

export default {
    props: [
        'showMyData',
        'businessData',
        'isActivityDetail',
        'isGray',
        'isInList',
        'couponOverview',
        'isCoupon',
        'microCouponData',
        'isActivity'
    ],
    data() {
        return {
            myBusiness: 20,
            todyNewBusiness: 10,
            myVisitor: 40,
            totalVisitor: 400,
            jumpParams: {}
        };
    },
    created() {
        this.askStaffID();
    },
    filters: {
        filterNumber(val) {
            if (val == 0 || !val) {
                return '-';
            }
            return val;
        }
    },
    methods: {
        gotoBottom() {
            let offsetTop;
            if (this.isActivity) {
                offsetTop = document.getElementsByClassName('invite-customer-box')[0].offsetTop;
            } else {
                offsetTop = document.getElementsByClassName('effective-list')[0].offsetTop;
            }
            document.body.scrollTop = offsetTop;
            document.documentElement.scrollTop = offsetTop;
        },
        goMerchantList() {
            if (this.isGray) return;
            console.log('this.params', this.jumpParams);
            const { key, account, host, app_id } = this.jumpParams;
            const machineType = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
                navigator.userAgent
            )
                ? 'Mobile'
                : 'Desktop';
            if (machineType === 'Mobile') {
                console.log('移动端', navigator.userAgent);
                window.location.href = `${host}api/crm/market_login_business_list?key=${key}&account=${account}&from=0`;
            } else {
                console.log('Pc端', navigator.userAgent);
                window.location.href = `${host}crm/market_login_business_list?key=${key}&account=${account}&from=0&app_id=${app_id}`;
            }
        },
        async askStaffID() {
            let appId = getCookie('with_app_id');
            const clientType = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
                navigator.userAgent
            )
                ? 'Mobile'
                : 'Desktop';
            let client = clientType === 'Mobile' ? 0 : 1;
            let res = await getStaffId({
                user_id: localStorage.getItem('b_user_id'),
                from: client,
                app_id: appId
            });
            this.jumpParams = {
                key: res.data.key,
                account: res.data.wework_staff_id,
                host: res.data.host,
                app_id: appId
            };
        }
    }
};
</script>

<style lang="scss" scoped>
.business-wrapper {
    width: 100%;
    z-index: 100;
    box-sizing: border-box;
    margin-top: 0.08rem;
    .business {
        margin-top: 0.25rem;
        height: 1.66rem;
        background: white;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        justify-content: space-around;
        padding: 0.32rem;
        border-radius: 0.2rem;
        &.flex-start {
            justify-content: flex-start;
        }
        .activity-coupon,
        .micro-coupon {
            display: flex;
            align-items: center;
        }
        .line {
            margin: 0.16rem 0.12rem;
            height: 1.16rem;
            border-right: 1px solid #eee;
            &.xs-line {
                margin: 0.16rem 0.12rem;
            }
        }
        .visitor {
            display: flex;
        }
        .first-title {
            font-family: 'PingFang SC';
            font-size: 0.24rem;
            color: #666666;
            opacity: 0.699999988079071;
        }
        .bold-data {
            font-family: 'D-DIN';
            font-size: 0.48rem;
            font-weight: 700;
            padding: 0.08rem 0 0.04rem 0;
        }
        .today-new {
            display: flex;
            color: #666666;
            font-size: 0.24rem;
            align-items: center;
            .business-number {
                margin-left: 0.02rem;
                color: #ff781f;
            }
            .visitor-number {
                margin-left: 0.04rem;
                color: #1472ff;
            }
        }
    }
    .box-shadow {
        box-shadow: 2px 2px 20px 2px rgb(189 189 189 / 60%);
    }
    .total-visitor {
        margin-left: 0.375rem;
    }
}
</style>
