<template>
    <div class="micro-coupon-list">
        <div v-for="(item, index) in couponList" :key="index" class="micro-coupon-item border-bottom-1">
            <div class="user-base">
                <div class="left">
                    <img class="user-avatar" :src="item.wx_avatar" alt="" />
                    <div class="user-name">{{ item.wx_nickname }}</div>
                </div>
                <div class="right">
                    <div class="time-receive">
                        {{ activeTab === 1 ? `领取时间：${item.updated_at}` : `用券时间：${item.updated_at}` }}
                    </div>
                </div>
            </div>
            <div class="user-tag">
                <div class="coupon-list">
                    <span class="coupon-text">{{ activeTab === 1 ? '已领优惠券：' : '已用优惠券：' }}</span>
                    <div
                        v-for="(coupon, j) in item.coupon_receive_info || item.coupon_use_info"
                        :key="j"
                        class="coupon-item"
                    >
                        <span class="tag">{{ coupon.title }}{{ activeTab === 1 ? `*${coupon.cnt || '1'}` : '' }}</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'MicroCouponList',
    props: {
        activeTab: {
            type: Number,
            default: 1
        },
        couponList: {
            type: Array,
            default: () => []
        }
    }
};
</script>

<style lang="scss" scoped>
.micro-coupon-list {
    .micro-coupon-item {
        padding: 0.22rem;
        margin-bottom: 0.22rem;
        margin-bottom: 0.22rem;
        border-radius: 0.32rem;
        box-shadow: 0 2px 12px 0 rgb(0 0 0 / 10%);
        background-color: #fff;
        .user-base {
            display: flex;
            justify-content: space-between;
            align-items: flex-start;
            .left {
                display: flex;
                align-items: center;
                width: 0;
                flex: 1;
                margin-right: 0.16rem;
            }
            .user-avatar {
                display: flex;
                width: 0.8rem;
                height: 0.8rem;
                border-radius: 50%;
            }
            .user-name {
                margin-left: 0.24rem;
                font-weight: 500;
                color: #333;
                text-overflow: ellipsis;
                white-space: nowrap;
                overflow: hidden;
            }
            .time-receive {
                font-size: 0.2rem;
                color: #999;
            }
        }
        .user-tag {
            display: flex;
            margin-top: 0.36rem;
            .coupon-text {
                font-size: 0.24rem;
                flex-shrink: 0;
                margin-right: 0.12rem;
                color: #999;
            }
            .coupon-list {
                display: flex;
                flex-wrap: wrap;
                width: 0;
                flex: 1;
            }
            .coupon-item {
                margin-right: 0.2rem;
                margin-bottom: 0.2rem;
                .tag {
                    font-size: 0.2rem;
                    padding: 0.04rem 0.08rem;
                    border-radius: 0.05rem;
                    background: #ebf3ff;
                    color: #1472ff;
                    white-space: nowrap;
                }
            }
        }
    }
}
</style>
