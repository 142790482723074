<template>
    <div>
        <div :class="['wrapper', { 'coupon-tab': showTab }]" :style="{ background: bgColor || '#F5F6F9' }">
            <div v-if="activeTab === 0 || isCoupon" class="customer-list-wrap">
                <div
                    v-for="(item, index) in filterCustomerList"
                    :key="index"
                    class="render-list"
                >
                    <div class="eCard" style="width: 100vw">
                        <div
                            class="profile"
                            @click="goCustomerDetail(item.customer_id, item.state, item.merchant_key_id, item.is_valid)"
                        >
                            <img
                                v-default-avatar
                                v-imgCDN
                                :src="item.wx_avatar"
                                :class="{ opacity: !item.is_valid, 'filter-blur': item.state === 5 }"
                            />
                            <div :class="['name', !item.is_valid ? 'gray-color' : '']">
                                <div class="nick-name">
                                    <span :class="['nick-name-txt', item.state === 5 ? 'filter-text' : '']">
                                        {{ item.wx_nickname | handleNickName }}
                                    </span>
                                    <span class="time">
                                        最近访问:
                                        <span class="">{{ item.updated_time | filterTimeYear }}</span>
                                    </span>
                                </div>
                                <div class="right-tips" v-if="isInList">
                                    <!-- 去掉标签 -->
                                    <!-- <div class="label-tips business-tips" v-if="[2, 4].includes(item.state)">
                                        {{ getTipsLabel(item) }}
                                    </div>

                                    <div class="label-tips visitor-tips" v-if="[0, 1, 5].includes(item.state)">
                                        {{ getTipsLabel(item) }}
                                    </div>

                                    <div class="label-tips customer-tips" v-if="item.state === 3">
                                        {{ getTipsLabel(item) }}
                                    </div> -->
                                    <!--   <div class="txt-tips" v-if="item.protect_time && item.state == 1">
                                        {{ item.protect_time }}天内注册店铺成为商机
                                    </div>
                                    <div class="txt-tips" v-if="item.state == 5">已被管理员操作换绑</div>
                                    <div class="txt-tips" v-if="item.state == 0">再次分享激活客户</div>
                                    <div class="txt-tips" v-if="item.state == 4">不属于你的商机</div>-->

                                    <div class="txt-tips">
                                        {{ stateDict[item.state] ? stateDict[item.state](item) : '' }}
                                    </div>
                                    <div v-if="item.state === 5 && item.bind_time" class="bind-time">
                                        锁定时间：{{ bindTime(item.bind_time, item.updated_time) }}
                                    </div>
                                </div>
                                <!-- <span>已失效</span> -->
                            </div>
                        </div>
                        <div
                            class="process-card flex flex-column-between"
                            v-if="isInList && item.state !== 0 && item.state !== 5"
                            :class="[needClue == false ? 'distance' : '']"
                        >
                            <div class="process-line">
                                <div class="flex flex-start">
                                    <div class="bind-wx">
                                        <img class="process-ball" :src="judgeLockCustomerStatus(item.state)" />
                                    </div>
                                    <div
                                        class="process-half-line"
                                        :class="[[2, 3, 4].includes(item.state) ? 'process-half-line-high-light' : '']"
                                    ></div>
                                </div>
                                <div class="bind-phone">
                                    <img class="process-ball" :src="judgeBusinessOpportunityStatus(item.state)" />
                                    <!-- <div
                                        :class="[item.state == 3 ? 'process-half-line-high-light' : '']"
                                        class="process-half-line"
                                    ></div> -->
                                </div>
                                <!-- 去掉成交节点 -->
                                <!-- <div class="flex flex-column">
                                    <img  class="process-ball" :src="judgeBusinessCompleteStatus(item.state)" />
                                    <div class="process-txt">成交</div>
                                </div> -->
                            </div>
                            <div class="process-time flex flex-between">
                                <div class="txt visited" :class="judgeShowDataTxt(item.state)">
                                    {{ item.created_time | filterTimeSeconds }}
                                </div>
                                <div
                                    v-if="[2, 3, 4].includes(item.state)"
                                    class="txt be-business"
                                    :class="judgeShowDataTxt(item.state)"
                                >
                                    {{ item.current_allocated_at }}
                                </div>
                                <!-- <div v-if="item.state == 3" class="txt deal" :class="judgeShowDataTxt(item.state)">
                                    {{ item.first_pay_at }}
                                </div> -->
                            </div>
                        </div>
                        <!-- 被他人锁客不展示链路 -->
                        <div class="process-clue" v-if="needClue && isInList && item.state !== 5">
                            <div class="origin" @click="goCustomerDetail(item.share_user_id)">
                                <div>分享来自：</div>
                                <img class="avatar" v-default-avatar v-imgCDN :src="item.share_user_wx_avatar" />
                                <div class="user_name">
                                    {{ item.share_user_wx_nickname | handleNickName }}
                                </div>
                            </div>
                            <div class="clue">
                                <div style="display: flex; align-items: center" :key="index">
                                    <div
                                        class="wx_avatar_box"
                                        style="display: flex"
                                        v-for="(elt, index) in getShareLinkList(item.share_link_list)"
                                        :key="index"
                                    >
                                        <img v-if="elt" class="avatar" v-default-avatar v-imgCDN :src="elt.wx_avatar" alt="" />
                                        <img v-else class="avatar ellipsis" :src="ellipsis" alt="" />
                                        <img
                                            class="arrow"
                                            style="width: 0.2rem; height: 0.2rem"
                                            :src="arrowIcon"
                                            alt=""
                                        />
                                    </div>
                                </div>

                                <div
                                    style="margin-left: 0.1rem"
                                    @click="toggleCluePopUP(true, 0, item.customer_id, item.activity_id)"
                                >
                                    分享链路
                                </div>
                                <div>
                                    <img class="multi-avatar-extend" :src="arrowIcon" />
                                </div>
                            </div>
                        </div>
                        <div class="activity_origin" v-if="isVisitors" @click="handleToActivity(item)">
                            活动来自：{{ item.activity_title }}
                        </div>
                    </div>
                </div>
                <van-action-sheet
                    v-model="showClue"
                    get-container="#app"
                    @closed="toggleCluePopUP(false, 3)"
                    @click="toggleCluePopUP(false, 3)"
                    title="分享链路"
                >
                    <div class="content-wrapper" v-show="!isLoading">
                        <div class="content" v-for="(item, index) in clueList" :key="index">
                            <div class="icon-wrapper">
                                <img
                                    v-default-avatar
                                    class="clue-avatar"
                                    style="width: 0.32rem; height: 0.32rem"
                                    :src="downArrow"
                                />
                                <img
                                    v-default-avatar
                                    class="dot"
                                    v-show="index != clueList.length - 1"
                                    :src="downPoint"
                                />
                            </div>
                            <div class="">
                                <div class="profile pop-profile">
                                    <img
                                        class="share-link-avatar"
                                        v-default-avatar
                                        v-imgCDN
                                        :src="item[1].wx_avatar"
                                        :class="[!item.is_valid ? 'opacity' : '']"
                                    />
                                    <div
                                        style="padding-bottom: 0"
                                        :class="['name', !item.is_valid ? 'gray-color' : '']"
                                    >
                                        <div class="nick-name">
                                            <span>{{ item[1].wx_nickname | handleClueName }}</span>
                                            <span class="time" v-if="index != 0">
                                                最早访问:
                                                <span class="">{{ item[1].created_at }}</span>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="loading" v-if="isLoading">
                        <img src="@/assets/images/loading.gif" />
                    </div>
                </van-action-sheet>
            </div>
            <div v-if="activeTab !== 0 && isMicroPage && !isCoupon" class="micro-coupon">
                <micro-coupon-receive :coupon-list="customerList" :active-tab="activeTab" />
            </div>
            <div v-if="!customerList.length" :class="['no-customer', isJoin ? 'background-white' : '']">
                <div class="invalid-module" v-show="!isLoading && isLoaded && !customerList.length">
                    <img class="invalid" src="@/assets/images/promotion_invalid.png" />
                    <span>{{ inviteText }}</span>
                </div>
                <!-- <img
                    v-show="showTab && (!isLoaded || isLoading)"
                    src="@/assets/images/goose.gif"
                    v-default-avatar
                    src="@/assets/images/loading.gif"
                    class="goose"
                    alt=""
                /> -->
            </div>
        </div>
        <!-- <div>
            <div :class="['no-customer', isJoin ? 'background-white' : '']">
                <div class="invalid-module" v-show="!isLoading && isLoaded && !customerList.length">
                    <img class="invalid" src="@/assets/images/promotion_invalid.png" />
                    <span>{{ inviteText }}</span>
                </div>
                <img class="goose" v-show="!isLoaded || isLoading" src="@/assets/images/goose.gif" alt="" />
            </div>
        </div> -->
    </div>
</template>

<script>
import { formatTime } from '@/utils/tools.js';
import { fetchClueNet, getStaffId } from '../../../../api/index';
import { getCookie } from '@/utils/cookies.js';
import microCouponReceive from './microCouponList';

const baseRoot = 'https://wechatapppro-1252524126.file.myqcloud.com/appAKLWLitn7978/image/b_u_5b2225aa46488_oGKN7IvA';

const processCompleteIconUrl = `${baseRoot}/kyaz99v005ug.png`;
const processingIconUrl = `${baseRoot}/kyaz99v30jyq.png`;
const processingWarningUrl = `${baseRoot}/kye006d30cdy.png`;
export default {
    props: [
        'activityId',
        'customerList',
        'needClue',
        'isJoin',
        'isGray',
        'isInList',
        'isLoaded',
        'bgColor',
        'isVisitors',
        'showTab',
        'activeTab',
        'isMicroPage',
        'isCoupon'
    ],
    components: {
        microCouponReceive
    },
    computed: {
        inviteText() {
            if (this.activeTab === 0) {
                // 已经参加活动
                if (this.isJoin) {
                    return `你还未邀请到客户，快去推广活动吧～`;
                }
                return `快去推广活动吧～`;
            } else if (this.activeTab === 1) {
                return '还没有客户领取优惠券，快去推广活动吧～';
            } else {
                return '还没有客户使用优惠券，快去推广活动吧～';
            }
        },
        filterCustomerList() {
            // 临时解决方案，解决数据重复的问题
            let data = this.customerList || [];
            const result = [];
            const listIds = {};
            data.forEach((row) => {
                const { customer_id } = row;
                if (!listIds[customer_id]) {
                    result.push(row);
                }
                listIds[customer_id] = true;
            });
            return result;
        }
    },
    data() {
        return {
            downPoint: `${baseRoot}/kyawgu0n06rt.png`,
            downArrow: `${baseRoot}/ky9w1p8x09ky.png`,
            ellipsis: `${baseRoot}/ky9tc9wi06v8.png`,
            arrowIcon: `${baseRoot}/ky9tc9wc0t5o.png`,
            showClue: false,
            clueList: [],
            firstBallUrl: '',
            secondBallUrl: '',
            thirdBallUrl: '',
            jumpParams: {},
            isLoading: false,
            currentItem: {},
            stateDict: {
                0: () => '分享锁定微信',
                1: ({ protect_time }) => (protect_time ? `锁定微信剩余有效期${protect_time}天` : ''),
                2: () => '',
                3: () => '客户',
                4: () => '',
                5: () => '已被他人锁定微信'
            },
            tipsDict: {
                0: '访客',
                1: '锁客',
                2: '商机',
                3: '客户',
                4: '商机',
                5: '访客'
            }
        };
    },
    filters: {
        filterTimeSeconds(val) {
            return formatTime(val * 1000, 'seconds');
        },
        filterTimeYear(val) {
            return val ? formatTime(val * 1000, 'year') : '';
        },
        // 处理名字
        handleNickName(val) {
            if (val && val.length > 8) {
                return val.substring(0, 8) + '...';
            }
            return val;
        },
        handleClueName(val) {
            if (val && val.length > 15) {
                return val.substring(0, 15) + '...';
            }
            return val;
        }
    },
    created() {
        this.askStaffID();
    },
    methods: {
        // 获取手机号年份早于或晚于访问，保留日期,不显时间，同年去掉年份，显示到分
        bindTime(bindTime, updatedTime) {
            let createTime = formatTime(updatedTime * 1000, 'seconds');
            let createTimeArr = String(createTime).split('-');
            let bindTimeArr = String(bindTime).split('-');
            if (createTimeArr[0] === bindTimeArr[0]) {
                let date = bindTime.replace(`${bindTimeArr[0]}-`, '');
                let time = date.split(':');
                let dateT = `${time[0]}:${time[1]}`;
                return dateT;
            } else {
                let date = bindTime.split(' ');
                return date[0];
            }
        },
        handleToActivity(item) {
            const { last_activity_id } = item;
            this.$router.push({ name: 'promotionDetail', params: { id: last_activity_id } });
        },
        getTipsLabel(item) {
            return this.tipsDict[item.state] || ''; // 233
        },
        getShareLinkList(list) {
            if (list.length > 5) {
                return [...list.slice(0, 2), '', ...list.slice(-2)];
            }
            return list;
        },
        judgeShowDataTxt(state) {
            return [state == 3 || state == 2 ? 'txt-blank' : 'txt-blank'];
        },
        async goMerchantList(value) {
            if (this.isGray) return;
            this.jumpParams.business_opportunity_id = value;
            console.log('this.params', this.jumpParams);
            const { key, account, business_opportunity_id, host, app_id } = this.jumpParams;
            const machineType = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
                navigator.userAgent
            )
                ? 'Mobile'
                : 'Desktop';
            if (machineType === 'Mobile') {
                console.log('移动端', navigator.userAgent);
                window.location.href = `${host}api/crm/market_login_business_detail?key=${key}&account=${account}&business_opportunity_id=${business_opportunity_id}`;
            } else {
                console.log('Pc端', navigator.userAgent);
                window.location.href = `${host}crm/market_login_business_detail?key=${key}&account=${account}&business_opportunity_id=${business_opportunity_id}&app_id=${app_id}`;
            }
        },
        async askStaffID() {
            let appId = getCookie('with_app_id');
            const clientType = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
                navigator.userAgent
            )
                ? 'Mobile'
                : 'Desktop';
            let client = clientType === 'Mobile' ? 0 : 1;
            let res = await getStaffId({
                user_id: localStorage.getItem('b_user_id'),
                from: client,
                app_id: appId
            });
            this.jumpParams = {
                key: res.data.key,
                account: res.data.wework_staff_id,
                host: res.data.host,
                app_id: appId
            };
        },
        //锁客状态
        judgeLockCustomerStatus(status) {
            switch (status) {
                case 1:
                    return processCompleteIconUrl;
                case 2:
                    return processCompleteIconUrl;
                case 3:
                    return processCompleteIconUrl;
                case 4:
                    return processCompleteIconUrl;
                default:
                    return processCompleteIconUrl;
            }
        },
        //商机状态
        judgeBusinessOpportunityStatus(status) {
            switch (status) {
                case 0:
                    return processingIconUrl;
                case 1:
                    return processingIconUrl;
                case 2:
                    return processCompleteIconUrl;
                case 3:
                    return processCompleteIconUrl;
                case 4:
                    return processCompleteIconUrl;
                case 5:
                    return processingIconUrl;
                default:
                    return processingWarningUrl;
            }
        },
        //成交状态
        judgeBusinessCompleteStatus(status) {
            switch (status) {
                case 1:
                    return processingIconUrl;
                case 2:
                    return processingIconUrl;
                case 3:
                    return processCompleteIconUrl;
                case 4:
                    return processingIconUrl;
                default:
                    return processingIconUrl;
            }
        },
        async takeClueRoad(customer_id, activity_id) {
            this.isLoading = true;
            let params = {
                customer_id: customer_id,
                activity_id: this.activityId || activity_id
            };
            try {
                let res = await fetchClueNet(params);
                let clueList = res.data.list;
                let staff = res.data.share_staff; //员工自己
                let staffSelf = [];
                staffSelf[0] = 'share';
                staffSelf[1] = staff;
                let clues = Object.entries(clueList);
                clues.unshift(staffSelf);
                this.clueList = clues;
                this.isLoading = false;
            } catch (e) {
                console.log(e);
                this.isLoading = false;
            }
        },
        toggleCluePopUP(status, zValue, customer_id = '', activity_id, item) {
            if (!this.needClue) return;
            this.currentItem = item;
            if (status) this.takeClueRoad(customer_id, activity_id);
            this.showClue = status;
            document.getElementsByClassName('btn-box')[0].style = `z-index: ${zValue}`;
        },
        goCustomerDetail(customer_id, state = 0, business_opportunity_id = '') {
            const query = { customer_id, state };
            if (!customer_id || state === 5) return;
            if (state !== 2 && state !== 3) {
                return this.$router.push({ name: 'customerDetail', query });
            }
            // 商机 或 客户状态需要判断是否能跳转
            if ((state === 2 || state === 3) && this.isInList && !this.isGray) {
                // 有效跳crm
                return this.goMerchantList(business_opportunity_id);
            }
            this.$router.push({ name: 'customerDetail', query });
        }
    }
};
</script>

<style lang="scss" scoped>
::v-deep .van-overlay {
    background-color: rgba(0, 0, 0, 0.2);
}
::v-deep .van-action-sheet {
    max-height: 60%;
}

@mixin step-txt {
    position: absolute;
    display: block;
    left: 50%;
    top: 0.48rem;
    transform: translateX(-50%);
    font-size: 0.2rem;
    white-space: nowrap;
}

@mixin txt {
    color: #999999;
    font-size: 0.24rem;
    font-weight: 400;
}

.flex {
    display: flex;
    &-column {
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
    }
    &-column-between {
        flex-direction: column;
        justify-content: space-between;
    }
    &-start {
        justify-content: flex-start;
    }
    &-between {
        justify-content: space-between;
    }
}
.wrapper {
    width: 100%;
    //background: #fff;
    background: #f5f6f9;
    padding: 0.2rem 0.1rem; // 0.32
    box-sizing: border-box;
    padding-bottom: 0.36rem;
    .render-list {
        background: #fff;
        padding: 0.22rem;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        // height: 0.8rem;
        justify-content: space-between;
        margin-bottom: 0.22rem;
        //padding-bottom: 0.24rem;
        border-radius: 0.32rem;
        box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
        .eCard {
            //border-bottom: 1px solid #eee;
            .activity_origin {
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                width: 6.5rem;
                @include txt;
            }
            .time {
                @include txt;
                white-space: nowrap;
            }
        }
        img {
            width: 0.8rem;
            height: 0.8rem;
            border-radius: 100%;
        }
        .filter-blur {
            border-radius: 100%;
            filter: blur(0.1rem);
            -webkit-filter: blur(0.1rem);
            -moz-filter: blur(0.1rem);
            -ms-filter: blur(0.1rem);
        }
        .distance {
            margin-bottom: 16px;
        }
        .avatar {
            width: 0.32rem;
            height: 0.32rem;
        }
        .process-clue {
            display: flex;
            justify-content: space-between;
            padding: 16px 0;
            @include txt;
            // border-bottom: 1px solid #eee;
            .origin {
                display: flex;
                align-items: center;
                .user_name {
                    margin-left: 0.1rem;
                    width: 1.2rem;
                    padding-right: 0.1rem;
                    box-sizing: border-box;
                    overflow: hidden;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                }
            }
            .clue {
                display: flex;
                align-items: center;
                .multi-avatar-extend {
                    width: 0.32rem;
                    height: 0.32rem;
                    display: flex;
                    align-items: center;
                }
                .wx_avatar_box {
                    display: flex;
                    align-items: center;
                    &:last-child {
                        .arrow {
                            display: none;
                        }
                    }
                }
            }
        }
        .right-tips {
            .label-tips {
                padding: 2px 4px;
                height: 16px;
                display: flex;
                align-items: center;
                justify-content: center;
                width: 28px;
                margin-left: 1.5rem;
                margin-bottom: 0.2rem;
                font-size: 10px;
                text-align: right;
                margin-left: auto;
                flex: 1;
            }
            .txt-tips {
                color: #666666;
                font-weight: 400;
                font-size: 0.24rem;
                text-align: end;
                padding-bottom: 0.18rem;
            }
            .bind-time {
                @include txt;
                text-align: right;
            }
            .business-tips {
                background: #fff3eb;
                color: #ff781f;
            }
            .visitor-tips {
                background: #ebf3ff;
                color: #1472ff;
                border-radius: 0.05rem;
            }
            .customer-tips {
                color: #07c160;
                background: #ecfff5;
            }
        }
    }
    .background-white {
        margin-top: 0.32rem;
        position: relative;
        background: #ffffff;
        min-height: calc(100vh - 2.88rem);
        border-top-left-radius: 0.32rem;
        border-top-right-radius: 0.32rem;
        .head {
            padding: 0.16rem 0.32rem;
            .title {
                font-size: 0.32rem;
                color: #333333;
                font-weight: 500;
            }
            .num {
                font-size: 0.28rem;
                color: #333333;
                font-weight: 500;
            }
            position: absolute;
            top: 0;
            left: 0;
        }
    }

    .loading-wrapper {
        display: flex;
        justify-content: center;
        .goose {
            width: 2rem;
        }
    }
    .no-customer {
        background: unset;
        width: 100%;
        display: flex;
        align-items: center;
        flex-direction: column;
        font-size: 0.28rem;
        color: #9e9e9e;
        .invalid-module {
            display: flex;
            flex-direction: column;
            align-items: center;
        }
        .invalid {
            width: 2.88rem;
            height: 2.88rem;
            margin-top: 1.08rem;
        }
        .goose {
            padding-top: 0.32rem;
            width: 2rem;
        }
    }
    .img_load {
        width: 100%;
        display: flex;
        justify-content: center;
        img {
            width: 0.5rem;
            height: 0.5rem;
            padding: 0.2rem 0;
        }
    }
}
.no-customer {
    background: unset;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    font-size: 0.28rem;
    color: #9e9e9e;
    .goose {
        width: 2rem;
    }
    .invalid-module {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .invalid {
        width: 2.88rem;
        height: 2.88rem;
        margin-top: 1.08rem;
    }
}
.content-wrapper {
    margin-bottom: 2.14rem;
}
.loading {
    padding: 2rem 0.5rem;
    text-align: center;
}
.content {
    display: flex;
    justify-content: center;
    margin-right: 0.2rem;
    .icon-wrapper {
        margin-top: 0.2rem;
        .clue-avatar {
            padding: 0.3rem 0.32rem;
            display: flex;
            align-items: center;
        }
        .dot {
            width: 0.06rem;
            border-radius: 0;
            height: 0.48rem;
            padding: 0 0.46rem;
            margin-top: 0.16rem;
        }
    }
}

.profile {
    display: flex;
}

.pop-profile {
    padding: 0.3rem 0 0.3rem 0.32rem;
    background: #fafafa;
    align-items: center;
    width: 5.9rem;
    border-radius: 8px;
}
.process-card {
    background: #fafafa;
    min-height: 1.6rem;
    .process-line {
        display: flex;
        justify-content: center;
        padding-top: 0.24rem;
        .bind-wx {
            position: relative;
            &::after {
                content: '锁定微信';
                @include step-txt;
            }
        }
        .bind-phone {
            position: relative;
            &::after {
                content: '获取手机号';
                @include step-txt;
            }
        }
        .process-ball {
            width: 0.4rem;
            height: 0.4rem;
        }
        .process-half-line {
            width: 4.2rem;
            height: 1px;
            margin-top: 0.18rem;
            border-top: solid #ebebeb 2px;
        }
        .process-half-line-high-light {
            border-top: solid #1472ff 2px;
        }
    }
    .process-time {
        padding: 0 0.2rem 0.2rem;
        .txt {
            color: #666666;
            font-size: 0.2rem;
            text-align: center;
        }
    }
}

.share-link-avatar {
    width: 0.8rem;
    height: 0.8rem;
    border-radius: 100%;
}

.name {
    margin-left: 0.24rem;
    font-size: 0.28rem;
    color: #333333;
    flex: 1;
    font-weight: 500;
    height: 100%;
    display: flex;
    justify-content: space-between;
    padding-bottom: 0.36rem;
    // align-items: flex-end;
    .nick-name {
        display: flex;
        flex-direction: column;
        -webkit-box-orient: vertical;
        .nick-name-txt {
            max-width: 2.8rem;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            padding-bottom: 0.15rem;
        }
        .filter-text {
            box-shadow: 0 0 0.1rem 0.04rem white;
            text-shadow: 0 0 0.18rem black;
            opacity: 0.8;
            color: rgba(0, 0, 0, 0);
            padding-left: 0.12rem;
            padding-top: 0.07rem;
            padding-bottom: 0.08rem;
            box-sizing: border-box;
        }
    }
}
</style>
