<template>
    <div class="back-center" @click="goPromotionCenter()" v-if="showGoCenterBtn">
        <div>推广中心</div>
    </div>
</template>
<script>
export default {
    name: 'PromotionCenterBtn',
    data() {
        return {
            showGoCenterBtn: false
        };
    },
    mounted() {
        if (this.$route.query.show_center) {
            this.showGoCenterBtn = true;
        }
        if (
            window.location.href.includes('/staff_promotion/customer_list_btn') ||
            window.location.href.includes('/staff_promotion/activity_detail_btn')
        ) {
            this.showGoCenterBtn = true;
        }
    },
    methods: {
        goPromotionCenter() {
            this.$router.push({
                path: '/staff_promotion'
            });
        }
    }
};
</script>
<style lang="scss" scope>
.back-center {
    position: fixed;
    right: 0.32rem;
    bottom: 1.8rem;
    width: 1.2rem;
    height: 1.2rem;
    background: #fff;
    border-radius: 50%;
    box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.08);
    text-align: center;
    z-index: 99;
    display: flex;
    justify-content: center;
    align-items: center;
    div {
        width: 0.6rem;
        height: 0.8rem;
        font-size: 0.28rem;
        color: #333333;
        word-wrap: break-word;
        text-align: center;
    }
}
</style>
