<template>
  <div class="rank-list">
    <div class="rank-tab">
      <div class="rank-tab-item"
        v-for="(item, index) in tabs"
        :key="index"
        @click="handleSwitch(item, index)"
        :class="{ 'active': rankType === index }">
        {{ item.label }}
      </div>
    </div>
    <div v-if="rankData.length" class="rank-content">
      <div v-for="(item, index) in rankData" :key="index" class="rank-content-item">
        <div class="item-left">
          <div class="user-rank">
            <img v-if="index < 3" :src="require(`../../../assets/images/rank_no${index + 1}.png`)" alt="">
            <span v-else>{{ index + 1 }}</span>
          </div>
          <div v-if="index < 3" class="user-avatar"
            :style="`border: 0.04rem solid ${['#f2cc91', '#8894af', '#bc907b'][index]}`">
            <img :src="item.avatar" alt="" />
          </div>
          <div v-else class="user-avatar"><img :src="item.avatar" alt="" /></div>
          <div class="user-name">{{ item.name }}</div>
        </div>
        <div class="item-right">
            {{ rankType === 0 ? `锁定微信 ${item.total_num} 个` : `获取手机号 ${item.total_num} 个` }}
        </div>
      </div>
    </div>
    <div v-if="rankData.length" class="rank-user border-top-1">
      <div class="rank-user-item">
        <div class="item-left">
          <div class="user-rank">{{ userRankData.total_num ? userRankData.position : '--' }}</div>
          <div class="user-avatar"><img :src="userRankData.avatar" alt="" /></div>
          <div class="user-name">{{ userRankData.name }}</div>
        </div>
        <div class="item-right">
          {{ rankType === 0 ? `锁定微信 ${userRankData.total_num} 个` : `获取手机号 ${userRankData.total_num} 个` }}
        </div>
      </div>
    </div>
    <div v-if="!rankData.length" class="rank-empty">
      <img class="empty-image" src="../../../assets/images/rank_nodata.png" alt="">
      <p class="empty-text">快去邀请，成为第一个上榜的人</p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'RankList',
  props: {
    visitorData: {
      type: Array,
      default: () => []
    },
    businessData: {
      type: Array,
      default: () => []
    },
    userVisitorData: {
      type: Object,
      default: () => {}
    },
    userBusinessData: {
      type: Object,
      default: () => {}
    },
    activeTab: {
      type: Number,
      default: ()=> 0
    }
  },

  data() {
    return {
      rankType: 0,
      rankData: [],
      userRankData: [],
      isRequestBusiness: false,
      tabs: [{ code: 0, label: '锁定微信排行榜' }, { code: 1, label: '获取手机号排行榜' } ]
    }
  },
  watch: {
      activeTab: {
          handler(val) {
              this.rankType = val;
              this.$emit('tab-switch', this.tabs[val])
          },
          immediate: true
      },
    rankType: {
      handler () {
        this.initRankData()
        if (this.rankType === 1) {
          if (!this.isRequestBusiness) {
            this.isRequestBusiness = true
            this.$emit('businessRank')
          }
        }
      },
      immediate: true
    },
    visitorData() {
      this.initRankData()
    },
    userBusinessData() {
      this.initRankData()
    }
  },
  methods: {
      handleSwitch(item, index) {
          this.rankType = index;
          this.$emit('tab-click', item)
          this.$emit('tab-switch', item);
      },
    initRankData() {
      if (this.rankType === 0) {
        this.rankData = this.visitorData
        this.userRankData = this.userVisitorData
      } else {
        this.rankData = this.businessData
        this.userRankData = this.userBusinessData
      }
    }
  },
}
</script>

<style lang="scss" scoped>
.rank-list {
  border-radius: 0.32rem;
  background-color: #fff;
  .user-rank {
    width: 0.64rem;
    height: 0.64rem;
    line-height: 0.64rem;
    font-size: 0.28rem;
    margin-right: 0.24rem;
    font-weight: 500;
    color: #999;
    text-align: center;
    img {
      display: block;
      width: 100%;
      height: 100%;
    }
  }
  .user-name {
    width: 0;
    flex: 1;
    font-size: 0.24rem;
    margin-left: 0.16rem;
    font-weight: 500;
    color: #333;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
  .user-avatar {
    width: 0.64rem;
    height: 0.64rem;
    box-sizing: border-box;
    border-radius: 50%;
    img {
      display: block;
      width: 100%;
      height: 100%;
      border-radius: 50%;
    }
  }
  .rank-empty {
    text-align: center;
    .empty-image {
      display: block;
      width: 4.8rem;
      padding-top: 0.8rem;
      margin: 0 auto;
    }
    .empty-text {
      font-size: 0.24rem;
      margin-top: 0.16rem;
      padding-bottom: 0.8rem;
      color: #ADADB4;
    }
  }
  .rank-tab {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 0.88rem;
    &-item {
      position: relative;
      margin-right: 0.48rem;
      font-size: 0.28rem;
      color: #666;
      &.active {
        font-weight: 700;
        color: #333;
      }
      &.active:after {
        content: "";
        position: absolute;
        bottom: -0.1rem;
        left: 50%;
        transform: translateX(-50%);
        width: 80%;
        height: 0.06rem;
        border-radius: 0.64rem;
        background-color: #1472FF;
      }
      &:last-child {
        margin-right: 0;
      }
    }
  }
  .rank-content {
    padding: 0.16rem 0.32rem;
    &-item {
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 0.96rem;
      .item-left {
        display: flex;
        align-items: center;
        flex: 1;
      }
      .item-right {
        font-size: 0.24rem;
        margin-left: 0.12rem;
        color: #666;
      }
    }
  }
  .rank-user {
    &-item {
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 1.08rem;
      padding: 0.16rem 0.32rem;
      .item-left {
        display: flex;
        align-items: center;
        flex: 1;
      }
      .item-right {
        font-size: 0.24rem;
         margin-left: 0.12rem;
        color: #666;
      }
    }
  }
}
</style>
