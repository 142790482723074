export default {
    methods: {
        hasPermission(callBack, errCallback = () => {}) {
            return this.$ajax('get_user_role')
                .then((res) => {
                    const { data } = res.data;
                    callBack(data);
                })
                .catch((err) => {
                    console.log('err: ', err);
                    errCallback(err);
                });
        }
    }
};
